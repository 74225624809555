// @flow
import React from 'react';
import _ from 'lodash/fp';
import {
	PopupSelect,
	List,
	Button,
	Flex,
	Box,
	Text,
	ModalDialog,
	Icon,
} from '@graphite/uneon';
import { Trans } from 'react-i18next';
import styled from '@emotion/styled';
import { Transition } from 'react-transition-group';
import logger from '@graphite/logger';
import type { TId, TWidgets, TWidget, TWidgetDiff } from '@graphite/types';

import Presets from './Presets';
import EmptySite from './EmptySite';
import Site from './Site';

type TProps = $ReadOnly<{|
	id: TId,
	containerId: TId,
	project: TWidget,
	sites: TWidgets,
	presets: TWidgets,
	t: (string) => string,
	onRemove: (TId, ?TId) => void,
	onCreateSite: (TId, ?TId) => void,
	onUpdate: (TId, TWidgetDiff) => void,
	onSelect: (TId) => void,
	onCreatePreset: (TId) => void,
|}>;

const headerSx = {
	justifyContent: 'space-between',
	alignItems: 'center',
	marginBottom: '30px',
	paddingBottom: '20px',
	borderBottomWidth: '1px',
	borderBottomStyle: 'solid',
	borderBottomColor: 'spec.lightblue30',
};

const sitesSx = {
	display: 'grid',
	gridTemplateColumns: 'repeat(auto-fill, minmax(312px, 1fr))',
};

const transitionStyles = {
	entering: 1,
	entered: 1,
	exiting: 0,
	exited: 0,
};
const projectNameSx = {
	marginBottom: '6px',
	paddingBottom: '8px',
	paddingTop: '7px',
	paddingRight: '9px',
	paddingLeft: '9px',
	marginLeft: '-9px',
	fontWeight: 'bold',
	borderWidth: '2px',
	borderStyle: 'solid',
	borderColor: 'transparent',
	borderRadius: '6px',
	outline: 'none',
	transition: 'border-color 0.3s ease-in',

	// outlineColor: 'palette.blue40','bg.secondaryminus',
};

const projectNameSxActive = {
	borderColor: 'bg.accent',
};

const StyledPresets = styled(Presets)`
	transition: opacity 250ms ease-in-out;
	opacity: ${({ state }) => transitionStyles[state]};
`;

const StyledButton = styled(Button)``;

const MAX_NUM_SYMB_NAME = 100;

const Project = ({
	id,
	containerId,
	project,
	sites,
	presets,
	t,
	onRemove,
	onCreateSite,
	onCreatePreset,
	onUpdate,
	onSelect,
}: TProps) => {
	const [isShowPresets, setIsShowPresets] = React.useState<boolean>(false);
	const [isShowModalDialog, setIsShowModalDialog] = React.useState<boolean>(false);
	const [isRenameAction, setIsRenameAction] = React.useState<boolean>(false);
	const [isOpenMenu, setStateMenu] = React.useState(false);
	const [innerHTML, setInnerHTML] = React.useState({ __html: project.name });

	const toggleOpenMenu = React.useCallback(
		(e) => {
			e?.stopPropagation();
			return setStateMenu(!isOpenMenu);
		},
		[isOpenMenu],
	);

	const toggleModalDialog = React.useCallback(() => {
		setIsShowModalDialog(!isShowModalDialog);
	}, [isShowModalDialog]);

	const onRemoveHandler = React.useCallback(() => {
		onRemove(id, containerId);
	}, [containerId, id, onRemove]);

	const onRemoveSiteHandler = React.useCallback(
		(id) => {
			onRemove(id, containerId);
		},
		[containerId, onRemove],
	);

	const onFocus = React.useCallback(() => setIsRenameAction(true), []);

	const onShowPresetsHandler = React.useCallback(() => {
		setIsShowPresets((isShowPresets) => {
			if (!isShowPresets) logger.info('openTemplates');
			return !isShowPresets;
		});
	}, []);

	const buttonRef = React.useRef(null);
	const editableRef = React.useRef();

	const list = React.useMemo(
		() => ({
			items: [
				{
					name: 'rename',
					label: t('Rename'),
				},
				{
					name: 'remove',
					label: t('Remove'),
				},
			],
			colors: 'primaryflat',
			activeColors: 'primaryflat',
			behavior: 'button',
		}),
		[t],
	);

	// for collaboration
	React.useEffect(() => {
		if (editableRef.current !== project.name) setInnerHTML({ __html: project.name });
	}, [project.name]);

	const onInputHandler = React.useCallback(
		(e: KeyboardEvent) => {
			e.stopPropagation();
			if (!editableRef.current) return;
			const el: HTMLElement = editableRef.current;

			const name = el.textContent.substring(0, MAX_NUM_SYMB_NAME).trim();

			if (!name.length) {
				el.textContent = project?.name ?? '';
				return;
			}
			onUpdate(project._id, {
				name,
			});
			setIsRenameAction(false);
		},
		[onUpdate, project._id, project.name],
	);

	const onKeyPressHandler = React.useCallback((e: KeyboardEvent) => {
		if (e.charCode === 13 && editableRef.current) {
			const el: HTMLElement = editableRef.current;
			const documentFrame = el.ownerDocument;
			documentFrame.execCommand('insertLineBreak', false, null);
			e.preventDefault();
		}

		if (
			editableRef.current?.textContent &&
			editableRef.current.textContent.length > MAX_NUM_SYMB_NAME
		) {
			const el: HTMLElement = editableRef.current;

			el.textContent = el.textContent.substring(0, MAX_NUM_SYMB_NAME);
			// moving cursor the end of text
			const range = document.createRange();
			const sel = window.getSelection();
			range.setStart(el, 1);
			range.collapse(true);
			sel.removeAllRanges();
			sel.addRange(range);
		}
	}, []);

	const onPasteHandler = React.useCallback((e) => {
		// cancel paste
		e.preventDefault();

		if (editableRef.current) {
			const el: HTMLElement = editableRef.current;
			const documentFrame = el?.ownerDocument;

			// get text representation of clipboard
			const text = (e.originalEvent || e).clipboardData.getData('text/plain');

			// insert text manually
			documentFrame.execCommand('insertHTML', false, text);
		}
	}, []);

	const selectText = (elem) => {
		if (!elem) return;
		let range;
		let selection;
		if (window.getSelection) {
			selection = window.getSelection();
			range = document.createRange();
			range.selectNodeContents(elem);
			selection.removeAllRanges();
			selection.addRange(range);
		}
	};

	const boundClick = React.useCallback(
		(e, itemName) => {
			e.stopPropagation();
			switch (itemName) {
				case 'remove':
					toggleModalDialog();
					break;
				case 'rename':
					selectText(editableRef.current);
					setIsRenameAction(true);
					break;
				default:
					break;
			}

			setStateMenu(false);
		},
		[toggleModalDialog],
	);

	return (
		<Box data-kind="project-sites">
			<Flex data-kind="project-control" sx={headerSx}>
				<Text
					variant="bodymd"
					color="text.primaryalt"
					data-kind="project-control-name"
					sx={
						isRenameAction
							? { ...projectNameSx, ...projectNameSxActive }
							: projectNameSx
					}
					contentEditable="true"
					spellCheck="false"
					onBlur={onInputHandler}
					onFocus={onFocus}
					onKeyPress={onKeyPressHandler}
					ref={editableRef}
					onPaste={onPasteHandler}
					dangerouslySetInnerHTML={innerHTML}
				/>
				<StyledButton
					data-kind="project-control-menu-open"
					variant="icon"
					mr="-25px"
					onClick={toggleOpenMenu}
					ref={buttonRef}
				>
					<Icon name="dots-horizontal-3" size="md" />
				</StyledButton>
				{isShowModalDialog && (
					<ModalDialog
						onClose={toggleModalDialog}
						headingText={t('Remove project')}
						withActionBtn
						actionBtnText={t('Remove')}
						actionBtnKind="negative"
						withCancelBtn
						onActionHandler={onRemoveHandler}
					>
						<Box>
							<Text as="span" variant="bodymd" color="text.primaryalt">
								<Trans i18nKey="All project websites will be: – deleted – unpublished – disconnected from domains This action cannot be undone.">
									All project websites will be:
									<br />
									– deleted
									<br />
									– unpublished
									<br />
									– disconnected from domains
									<br />
									<br />
									This action cannot be undone.
								</Trans>
							</Text>
						</Box>
					</ModalDialog>
				)}
			</Flex>

			<Box data-kind="project-sites" variant="grid" sx={sitesSx}>
				{_.map(
					(site) => (
						<Site
							key={site._id}
							site={site}
							containerId={id}
							onRemove={onRemoveSiteHandler}
							onSelect={onSelect}
							onUpdate={onUpdate}
							createPreset={onCreatePreset}
						/>
					),
					sites,
				)}
				<EmptySite
					insertSiteHandler={onShowPresetsHandler}
					isEmptySite={_.isEmpty(sites)}
				/>
			</Box>

			<Transition in={isShowPresets} timeout={250} unmountOnExit>
				{(state) => (
					<StyledPresets
						state={state}
						t={t}
						id={id}
						presets={presets}
						onCreateSite={onCreateSite}
						onShowPresetsHandler={onShowPresetsHandler}
					/>
				)}
			</Transition>
			{isOpenMenu && (
				<PopupSelect
					isOpen={isOpenMenu}
					anchorEl={buttonRef}
					offsetTop={30}
					offsetLeft={44}
					onClose={toggleOpenMenu}
					width="auto"
				>
					<Box data-kind="site-control-menu">
						{/* eslint-disable-next-line react/jsx-props-no-spreading */}
						<List {...list} onClick={boundClick} />
					</Box>
				</PopupSelect>
			)}
		</Box>
	);
};

export default React.memo<TProps>(Project);
