// @flow
import React from 'react';
import styled from '@emotion/styled';
import Frame, { FrameContextConsumer } from 'react-frame-component';
import { CacheProvider, Global, css } from '@emotion/core';
import createCache from '@emotion/cache';
import weakMemoize from '@emotion/weak-memoize';
import { useTranslation, Trans } from 'react-i18next';

import { PopupSelect, List, Button, Icon, Box, Text, ModalDialog } from '@graphite/uneon';

import Widget from 'Widget';
import type { TWidget, TWidgetDiff, TId } from '@graphite/types';

import previewImage from './img/site-preview.inline.svg';
import useWatchResize from './libs/use-watch-resize';

const memoizedCreateCacheWithContainer = weakMemoize((container) =>
	createCache({ container }),
);

type TProps = $ReadOnly<{|
	containerId: TId,
	site: TWidget,
	onRemove: (TId) => void,
	onUpdate: (TId, TWidgetDiff) => void,
	createPreset: (TId) => void,
	onSelect: (TId) => void,
|}>;

const siteSx = {
	marginBottom: '30px',
	position: 'relative',
	paddingRight: '9px',
	paddingLeft: '9px',
};

const siteNameSx = {
	color: 'text.primaryalt',
	a: {
		textDecoration: 'none',
		color: 'text.primary',
	},
	marginBottom: '6px',
	paddingBottom: '8px',
	paddingTop: '7px',
	paddingRight: '9px',
	paddingLeft: '9px',
	position: 'absolute',
	width: '100%',
	left: 0,
	outline: 'none',
	borderWidth: '2px',
	borderStyle: 'solid',
	borderColor: 'transparent',
	borderRadius: '6px',
	transition: 'border-color 0.3s ease-in',
};

const siteNameSxActive = {
	borderColor: 'bg.accent',
};
const MAX_NUM_SYMB_NAME = 100;

const StyledButton = styled(Button)``;

const wrapperActiveStyle = css`
	&:after {
		opacity: 0.7;
	}
	${StyledButton} {
		visibility: visible;
	}
`;
const WrapperFrame = styled(Box)`
	background: #fff;
	/* Расятгивает прямоугольник на разрешение 16:9 */
	padding-top: 56.25%;
	position: relative;
	border-radius: 6px;
	border-color: ${({ theme }) => theme.colors.bg.secondary};
	background-image: url(${previewImage});
	background-size: cover;
	background-position: center;
	overflow: hidden;
	margin-bottom: 12px;
	cursor: pointer;
	box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);

	&:after {
		content: ' ';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		background-color: ${({ theme }) => theme.colors.spec.darkblue70};
		transition: opacity 250ms ease-in-out;
	}

	${StyledButton} {
		visibility: hidden;
		position: absolute;
		top: 15px;
		right: 15px;
		z-index: 2;
	}

	&:hover {
		${wrapperActiveStyle}
	}

	${({ isActive }) => isActive && wrapperActiveStyle}
`;

const FrameStyled = styled(Frame)`
	position: absolute;
	top: 0;
	left: 0;
	width: 1200px;
	transform-origin: left top;
`;

const Site = ({
	site,
	containerId,
	onRemove,
	onSelect,
	onUpdate,
	createPreset,
}: TProps) => {
	const editableRef = React.useRef();
	const buttonRef = React.useRef(null);
	const [innerHTML, setInnerHTML] = React.useState({ __html: site.name });
	const [isOpenMenu, setStateMenu] = React.useState(false);
	const [isShowModalDialog, setIsShowModalDialog] = React.useState<boolean>(false);
	const [isRenameAction, setIsRenameAction] = React.useState<boolean>(false);

	const { t } = useTranslation();

	const toggleModalDialog = React.useCallback(() => {
		setIsShowModalDialog(!isShowModalDialog);
	}, [isShowModalDialog]);

	const onRemoveSite = React.useCallback(() => {
		onRemove(site._id);
	}, [onRemove, site._id]);

	const toggleOpenMenu = React.useCallback(
		(e) => {
			e?.stopPropagation();
			return setStateMenu(!isOpenMenu);
		},
		[isOpenMenu],
	);

	const onFocus = React.useCallback(() => setIsRenameAction(true), []);

	// for collaboration
	React.useEffect(() => {
		if (editableRef.current !== site.name) {
			setInnerHTML({ __html: site.name });
		}
	}, [site.name]);

	const onInputHandler = React.useCallback(
		(e: KeyboardEvent) => {
			e.stopPropagation();
			if (!editableRef.current) return;
			const el: HTMLElement = editableRef.current;

			const name = el.textContent.substring(0, MAX_NUM_SYMB_NAME).trim();

			if (!name.length) {
				el.textContent = site?.name ?? '';
				return;
			}
			onUpdate(site._id, {
				name,
			});
			setIsRenameAction(false);
		},
		[onUpdate, site._id, site.name],
	);

	const onKeyPressHandler = React.useCallback((e: KeyboardEvent) => {
		if (e.charCode === 13 && editableRef.current) {
			const el: HTMLElement = editableRef.current;
			const documentFrame = el.ownerDocument;
			documentFrame.execCommand('insertLineBreak', false, null);
			e.preventDefault();
		}

		if (
			editableRef.current?.textContent &&
			editableRef.current.textContent.length > MAX_NUM_SYMB_NAME
		) {
			const el: HTMLElement = editableRef.current;

			el.textContent = el.textContent.substring(0, MAX_NUM_SYMB_NAME);
			// moving cursor the end of text
			const range = document.createRange();
			const sel = window.getSelection();
			range.setStart(el, 1);
			range.collapse(true);
			sel.removeAllRanges();
			sel.addRange(range);
		}
	}, []);

	const onPasteHandler = React.useCallback((e) => {
		// cancel paste
		e.preventDefault();

		if (editableRef.current) {
			const el: HTMLElement = editableRef.current;
			const documentFrame = el?.ownerDocument;

			// get text representation of clipboard
			const text = (e.originalEvent || e).clipboardData.getData('text/plain');

			// insert text manually
			documentFrame.execCommand('insertHTML', false, text);
		}
	}, []);

	const { frameStyle, innerElRef } = useWatchResize();

	const list = React.useMemo(
		() => ({
			items: [
				{
					name: 'edit',
					label: t('Edit'),
				},
				{
					name: 'rename',
					label: t('Rename'),
				},
				{
					name: 'createPreset',
					label: t('Add to marketplace'),
				},
				{
					name: 'remove',
					label: t('Remove'),
				},
			],
			colors: 'primaryflat',
			activeColors: 'primaryflat',
			behavior: 'button',
		}),
		[t],
	);

	const selectText = (elem) => {
		if (!elem) return;
		let range;
		let selection;
		if (window.getSelection) {
			selection = window.getSelection();
			range = document.createRange();
			range.selectNodeContents(elem);
			selection.removeAllRanges();
			selection.addRange(range);
		}
	};

	const boundClick = React.useCallback(
		(e, itemName) => {
			e.stopPropagation();
			switch (itemName) {
				case 'remove':
					toggleModalDialog();
					break;
				case 'createPreset':
					createPreset(site._id);
					break;
				case 'edit':
					onSelect(site._id);
					break;
				case 'rename':
					setIsRenameAction(true);
					selectText(editableRef.current);
					break;
				default:
					break;
			}

			setStateMenu(false);
		},
		[toggleModalDialog, createPreset, onSelect, site._id],
	);

	const onSelectHandler = React.useCallback(() => {
		onSelect(site._id);
	}, [onSelect, site._id]);

	return (
		<Box data-kind="site" variant="grid.column" mb="27px" sx={siteSx}>
			<WrapperFrame
				ref={innerElRef}
				onClick={onSelectHandler}
				isActive={isOpenMenu}
			>
				<FrameStyled data-kind="site-iframe-render" style={frameStyle}>
					<FrameContextConsumer>
						{({ document }) => (
							<CacheProvider
								value={memoizedCreateCacheWithContainer(document.head)}
							>
								<Global
									styles={css`
										html,
										body {
											overflow: hidden;
											min-height: 100vh;
											background: #fff;
										}
									`}
								/>
								<Widget
									id={site._id}
									containerId={containerId}
									instanceId={null}
									originId={containerId}
								/>
							</CacheProvider>
						)}
					</FrameContextConsumer>
				</FrameStyled>
				<StyledButton
					data-kind="site-control-menu-open"
					variant={isOpenMenu ? 'accent.iconrounded' : 'white.iconrounded'}
					onClick={toggleOpenMenu}
					ref={buttonRef}
				>
					<Icon
						name="dots-horizontal-3"
						colors={isOpenMenu ? 'accent' : 'primary'}
					/>
				</StyledButton>
			</WrapperFrame>

			<Text
				data-kind="site-control-name"
				variant="bodysm"
				sx={isRenameAction ? { ...siteNameSx, ...siteNameSxActive } : siteNameSx}
				contentEditable="true"
				spellCheck="false"
				onBlur={onInputHandler}
				onFocus={onFocus}
				onKeyPress={onKeyPressHandler}
				ref={editableRef}
				onPaste={onPasteHandler}
				dangerouslySetInnerHTML={innerHTML}
			/>

			{isShowModalDialog && (
				<ModalDialog
					onClose={toggleModalDialog}
					headingText={t('Remove website')}
					withActionBtn
					actionBtnText={t('Remove')}
					actionBtnKind="negative"
					withCancelBtn
					onActionHandler={onRemoveSite}
				>
					<Text as="span" variant="bodymd" color="text.primaryalt">
						<Trans i18nKey="Website will be unpublished and disconnected from domain. This action cannot be undone.">
							Website will be unpublished and disconnected from domain.
							<br />
							<br />
							This action cannot be undone.
						</Trans>
					</Text>
				</ModalDialog>
			)}

			{isOpenMenu && (
				<PopupSelect
					isOpen={isOpenMenu}
					anchorEl={buttonRef}
					offsetTop={0}
					offsetLeft={44}
					onClose={toggleOpenMenu}
					width="auto"
				>
					<Box data-kind="site-control-menu">
						{/* eslint-disable-next-line react/jsx-props-no-spreading */}
						<List {...list} onClick={boundClick} />
					</Box>
				</PopupSelect>
			)}
		</Box>
	);
};

export default React.memo<TProps>(Site);
